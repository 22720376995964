import { Button, Divider, Typography } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import CouponImage from "assets/icons/cupom-top.png";
import Footer from "../common/footerContainer/FooterContainer";
import TitleContainer from "../common/titleContainer/TitleContainer";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import dayjs from "~/config/dayjs";
import { useTranslation } from "react-i18next";

const Coupon = (props) => {
	const classes = useStyles();

	const {  t } = useTranslation();

	const { coupons } = useSelector((store) => store.coupons);
	const { business } = useSelector((store) => store.business);

	const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));

	const handleClickCoupon = (item) => {
		const sectorName = sectors[0]?.name;
		if (sectorName !== "" && sectorName !== "Pet" && business?.status === "active") {
			props.history.push(`/business/${business?.id}`, { coupon: item });
		} else if (sectorName !== "" && sectorName === "Pet" && business?.status === "active") {
			props.history.push(`/pets-cadastrados/${business.id}`, { coupon: item });
		}
	};

	return (
		<TitleContainer
			title={t("Coupons available")}
			// subtitle={<CardBusiness />}
			handleClick={() => props.history.goBack()}
			footer={<Footer section={"cupons"} />}
			// loading={loading}
		>
			<div>
				{coupons &&
					coupons.items_list.map((item, index) => {
						const date = dayjs(item.coupon.expiration).utc();
						return (
							<div key={`reservations-${index}`}>
								<div variant="outlined" color="primary">
									<div key={`reservations-${index}`} style={{ display: "flex", flexDirection: "row" }}>
										<Button
											onClick={() => {
												handleClickCoupon(item);
											}}>
											<div className={classes.rectangle}>
												<Divider />
												<div>
													<img alt="coupon" src={CouponImage} style={{ width: "40px", height: "70px" }} />
												</div>
												<div className={classes.infor}>
													<Typography className={classes.nameService}>{item.coupon.description}</Typography>
													<Typography className={classes.expiration}>{date.format("DD/MM/YYYY")}</Typography>
												</div>
											</div>
										</Button>
									</div>
								</div>
							</div>
						);
					})}
			</div>
		</TitleContainer>
	);
};

export default withRouter(Coupon);

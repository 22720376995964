import { Divider, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import FeaturesSelected from "~/components/common/featuresSelected/FeaturesSelected";
import businessService from "~/services/businessService";
import SpinnerLoading from "~/components/common/spinnerLoading/SpinnerLoading";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useMixpanel } from "react-mixpanel-browser";
import PurpleCheckBox from "~/components/common/purpleCheckBox/purpleChackBox";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import servicesService from "~/services/servicesService";
import { useContext } from "react";
import { DialogBuilder } from "~/utils/DialogProvider";
import ScheduleDiv from "~/components/common/scheduleDiv/ScheduleDiv";
import ErrorDialog from "~/components/common/dialog";
import Utils from "~/helpers/Utils";
import couponServices from "~/services/couponServices";

const VendorServiceScheduling = (props) => {
	const dialog = useContext(DialogBuilder);
	const mixpanel = useMixpanel();
	const classes = useStyles();
	const selectedCategorieId = props?.selectedCategorie?.categorie?.id;
	const pet = props?.selectedCategorie?.pet;
	const coupon = props.coupon;
	const business = props.business;
	const preSelectedServiceId = props.preSelectedServiceId;

	const { t } = useTranslation();
	const { user } = useSelector((store) => store.auth);

	const [selectedServices, setSelectedServices] = useState([]);
	const [services, setServices] = useState([]);
	const [servicesTree, setServicesTree] = useState([]);
	const [openedBranchs, setOpenedBranchs] = useState([]);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [treeView] = useState(false);
	const [currentLevel, setCurrentLevel] = useState([]);
	const [openError, setOpenError] = useState(false);
	const language = JSON.parse(localStorage.getItem("@prit-web/language"));

	useEffect(() => {
		setLoading(true);
		if (business?.id) {
			if (coupon) {
				couponServices
					.getBusinessServiceCoupons(
						business.id,
						selectedCategorieId || business.categories[0].id,
						coupon.coupon.coupon_id,
						pet?.featureNames[0],
						pet?.featureNames[1]
					)
					.then((res) => {
						let businessServices = res.data;
						if (businessServices) {
							setServices(businessServices);
							let tree = servicesService.buildServiceTree(businessServices, business.language);
							if (pet) {
								tree = servicesService.petPreselection(tree, pet);
							}
							setServicesTree(tree);
							setCurrentLevel(tree);
						}
						setLoading(false);
					})
					.catch((err) => {
						dialog
							.withTitle(t("Unable to load the services"))
							.withMessage(err.message)
							.withAction(() => props.history.goBack())
							.show();
						setLoading(false);
					});
			} else {
				businessService
					.getBusinessService(
						business.id,
						selectedCategorieId,
						language,
						pet?.featureNames[0],
						pet?.featureNames[1]
					)
					.then((res) => {
						let businessServices = res.data;
						if (businessServices) {
							setServices(businessServices);
							let tree = servicesService.buildServiceTree(businessServices, language);
							setServicesTree(tree);
							if (preSelectedServiceId) {
								for (let i = 0; i < businessServices.length; i++) {
									if (businessServices[i].id === preSelectedServiceId) {
										tree = servicesService.servicePreselection(tree, businessServices[i]);
										setSelectedServices([businessServices[i]]);
									}
								}
							}
							if (pet) {
								tree = servicesService.petPreselection(tree, pet);
							}
							setCurrentLevel(tree);
						}
						setLoading(false);
					})
					.catch(
						({
							response: {
								data: { message },
							},
						}) => {
							dialog
								.withTitle(t("Unable to load the services"))
								.withMessage(message)
								.withAction(() => props.history.goBack())
								.show();
							setLoading(false);
						}
					);
			}
		}
	}, [language]);

	const openBranch = (branch) => {
		setOpenedBranchs([...openedBranchs, branch.id]);
	};

	const closeBranch = (branch) => {
		setOpenedBranchs(
			openedBranchs.filter((openedBranch) => {
				return openedBranch !== branch.id;
			})
		);
	};

	const isOpen = (branch) => {
		return openedBranchs.includes(branch.id);
	};

	const renderBranch = (branch) => {
		let selectable = branch?.branchs?.length === 0;

		return (
			<>
			<div
			style={{
				display: "flex",
				flexDirection: "column",
				height: "auto",
				padding: "10px",
				marginBottom: "10px",
				border: "1px solid #ddd",
				borderRadius: "8px",
				boxSizing: "border-box",
			}}
			onClick={() => {
				if (selectable) {
				if (selectedServices.includes(branch.service)) {
					removeSelectedService(branch.service);
				} else {
					handleSelectService(branch.service);
				}
				mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
					my_custom_prop: "foo",
				});
				mixpanel.track("web - selecionou serviço", {
					my_custom_prop: "foo",
				});
				} else {
				if (isOpen(branch)) {
					closeBranch(branch);
				} else {
					openBranch(branch);
				}
				}
			}}
			>
			<div
				style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				flexWrap: "wrap",
				width: "100%",
				}}
			>
				<div key={branch?.id} style={{ marginBottom: "8px" }}>
				<h4 className={classes.nameList} style={{ margin: 0 }}>{branch?.name}</h4>
				</div>
				{selectable ? (
				<PurpleCheckBox
					checkedIcon={<CheckCircleIcon />}
					checked={selectedServices.includes(branch.service)}
				/>
				) : isOpen(branch) ? (
				<KeyboardArrowDown />
				) : (
				<KeyboardArrowRight />
				)}
			</div>
			</div>
				{isOpen(branch) &&
					branch.branchs.map((nextBranch, index) => {
						return (
							<div style={{ display: "flex", flexDirection: "row", flexBasis: 2, marginLeft: "10px" }}>
								<div>
									<div
										style={{
											height: "27px",
											width: "1px",
											backgroundColor: index === 0 || !isOpen(branch.branchs[index - 1]) ? "#ddd" : "transparent",
										}}
									/>

									<div
										style={{
											height: "1px",
											width: "25px",
											backgroundColor: "#ddd",
										}}
									/>
									{!(index === branch.branchs.length - 1) && !isOpen(nextBranch) && (
										<div
											style={{
												height: "23px",
												width: "1px",
												backgroundColor: "#ddd",
											}}
										/>
									)}
								</div>
								<div style={{ width: "10px" }} />

								<div style={{ flex: 1 }}>{renderBranch(nextBranch)}</div>
							</div>
						);
					})}
			</>
		);
	};
	const findServiceById = (branchs, serviceId) => {
		for (const branch of branchs) {
			if (branch.service?.id === serviceId) {
				return branch.service.id;
			}
		}
	};

	const renderLevel = () => {
		let orderedCurrentLevel = currentLevel.slice();

		orderedCurrentLevel.sort((a, b) => a.name.localeCompare(b.name));
		return orderedCurrentLevel.map((branch) => {
			let selectable = branch?.branchs?.length === 0;
			let value = branch?.service;
			let isChecked = selectedServices.includes(value);

			return (
				<div
					className={classes.levelTileDiv}
					onClick={() => {
						if (selectable) {
							if (selectedServices.includes(branch.service)) {
								removeSelectedService(branch.service);
							} else {
								handleSelectService(branch.service);
							}
							mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
								my_custom_prop: "foo",
							});
							mixpanel.track("web - selecionou serviço", {
								my_custom_prop: "foo",
							});
						} else {
							setCurrentLevel(branch?.branchs);
						}
					}}>
					<div className={classes.levelTileAlign} style={{ display: "flex", alignItems: "center"}}>
						<div key={branch?.id} style={{ margin: "2% 0 2% 0"}}>
							<h4 className={classes.nameList}>{branch?.name}</h4>
						</div>
						<div style={{ display: "flex", alignItems: "center", marginRight: "-9px" }}>
							{value ? <h4 className={classes.price}>{Utils.formatMinutes(value.duration)}</h4> : ""}
							{value ? (
								<h4 className={classes.price}>{Utils.getServicePricingType(value, business)}</h4>
							) : (
								""
							)}
							{selectable ? (
								<PurpleCheckBox checkedIcon={<CheckCircleIcon />} checked={isChecked} />
							) : (
								<KeyboardArrowRight />
							)}
						</div>
					</div>
					<Divider />
				</div>
			);
		});
	};

	const handleSchedule = () => {
		if (selectedServices.length > 0) {
			mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
				my_custom_prop: "foo",
			});
			mixpanel.track("web - clicou continuar serviço", {
				my_custom_prop: "foo",
			});
			props.history.push(`/selecionar-data/${business.id}`, {
				services: selectedServices,
				coupon: coupon,
				// feature_items: feature_items,
			});
		} else {
			handleModalServices();
			window.scrollTo(0, window.innerHeight);
		}
	};

	const handleModalServices = () => {
		setOpenError(!openError);
	};

	const handleSelectService = (item) => {
		if (!selectedServices.includes(item)) {
			setSelectedServices([...selectedServices, item]);
		}
	};

	const removeSelectedService = (item) => {
		setSelectedServices(selectedServices.filter((element) => element.id !== item.id));
	};

	return (
		<Fragment>
			<SpinnerLoading loading={loading} />
			<div
				style={{
					display: "flex",
					//overflowX: selectedServices?.length > 0 ? "scroll" : "none",
					overflowY: "hidden",
					//marginTop: "15px",
				}}>
				<FeaturesSelected features={selectedServices} handleDelete={removeSelectedService} />
			</div>

			{/*<Paper component="form" className={classes.root} style={{ marginBottom: "15px", background: "#D9D9D9", borderRadius:"46px", height:"45px" }}>
				<IconButton className={classes.iconButton} aria-label="search" disabled={true}>
					<SearchIcon />
				</IconButton>
				<InputBase
					style={{ fontSize: "14px" }}
					value={search}
					onChange={(e) => {
						setSearch(e.target.value);
					}}
					className={classes.inputSearch}
					placeholder={t("Name Service")}
					inputProps={{ "aria-label": "search service" }}
				/>
			</Paper>*/}
			{business.categories.length > 1  && (
				<div style={{marginTop: -5}}>
					<Typography className={classes.levelTitle}>{t("Selecione um Serviço")}</Typography>
				</div>
			)}
			<div>
				{search.length > 0
					? servicesTree
							.sort((a, b) => a.name.localeCompare(b.name))
							.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
							.map((item) => <div key={item.id}>{renderBranch(item)}</div>)
					: services?.length > 0
					? treeView
						? servicesTree.map((item) => <div key={item.id}>{renderBranch(item)}</div>)
						: renderLevel()
					: !loading && <div className={classes.noServicesAvailble}>{t("No services available")}</div>}
			</div>
			<div
				style={{
					bottom: "60px",
					left: 0,
					position: "fixed",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					borderRadius: "25.5px",
					backgroundColor: "#fff",
				}}>
				{business ? (
					<ScheduleDiv business={business?.status !== "active"} handleClick={handleSchedule} label={t("Book")} />
				) : (
					<ScheduleDiv handleClick={handleModalServices} business={business?.status !== "active"} />
				)}
			</div>
			<ErrorDialog
				onOpen={openError}
				message={t("Selected Service")}
				onOk={() => {
					setOpenError(false);
				}}
				title={t("Attention")}
			/>
		</Fragment>
	);
	
};

export default withRouter(VendorServiceScheduling);

import Constants from "~/helpers/enums/Constants";
import couponServices from "~/services/couponServices";
import searchService from "~/services/searchService";
import servicesService from "~/services/servicesService";

const getCouponBusiness = (businessId) => (dispatch) => {
	couponServices.getCouponBusiness(businessId).then(({ data }) => {
		dispatch({
			type: Constants.GET_COUPON,
			payload: { coupons: data },
		});
	});
};

const getCouponService = (id, categoryId, vendorsPageService, couponId, feature_items) => (dispatch) => {
	couponServices.getBusinessServiceCoupons(id, categoryId, vendorsPageService, feature_items, couponId).then(({ data }) => {
		dispatch({
			type: Constants.GET_COUPON_SERVICE,
			payload: {
				couponService: servicesService.buildSubCategories(data.map((item) => item)),
			},
		});
	});
};

const getAvailableDatesCoupon = (businessId, services, coupon_id) => (dispatch) => {
	dispatch({ type: Constants.GET_AVAILABLE_DATES_COUPON, payload: { availableDatesCoupon: undefined } });
	couponServices.getAvailableDatesCoupon(businessId, services, coupon_id).then(({ data }) => {
		dispatch({ type: Constants.GET_AVAILABLE_DATES_COUPON, payload: { availableDatesCoupon: data } });
	});
};

const getSearchCoupon = (values) => (dispatch) => {
	searchService.postSearchCoupon(values).then(({ data }) => {
		dispatch({ type: Constants.GET_SEARCH_COUPON, payload: { searchCoupon: data } });
	});
};
export default { getCouponBusiness, getCouponService, getAvailableDatesCoupon, getSearchCoupon };

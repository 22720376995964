import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import ErrorDialog from "~/components/common/dialog";
import dayjs from "~/config/dayjs";
import searchService from "~/services/searchService";
import couponServices from "~/services/couponServices";
import MuiThemeSelectScheduleTime from "./MuiStylesSelectScheduleTime";
import FeaturesSelected from "~/components/common/featuresSelected/FeaturesSelected";
import { Typography } from "@material-ui/core";
import ProfessionalDiv from "~/components/common/professionalDiv/ProfessionalDiv";
import BusinessDiv from "~/components/common/businessDiv/BusinessDiv";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import { useTranslation } from "react-i18next";
import ScheduleProgress from "~/components/common/scheduleProgress/ScheduleProgress";
import TimeOptionsDiv from "~/components/common/TimeOptionsDiv";
import { useMixpanel } from "react-mixpanel-browser";
import { useContext } from "react";
import { DialogBuilder } from "~/utils/DialogProvider";
import ColorButton from "~/components/common/colorButton/ColorButton";
import SEO from "~/components/seo";
import WhatsAppButton from "~/components/common/whatsappButton/whatsappButton";
import _ from "lodash";
import camelizeKeys from "../../utils/camelizeKeys";

const SelectScheduleTime = (props) => {
	const bookingId = props?.location?.state?.bookingId;
	const reservationUpdate = props?.location?.state?.reservationUpdate;
	const feature_items = props?.location?.state?.feature_items;
	const coupon = props?.location?.state?.coupon;
	const reschedule = props?.location?.state?.reschedule;
	const date = props?.location?.state?.date;
	const selectedServices = props?.location?.state?.services;
	const dialog = useContext(DialogBuilder);
	const mixpanel = useMixpanel();
	const classes = MuiThemeSelectScheduleTime();
	const { user } = useSelector((store) => store.auth);
	const { business } = useSelector((store) => store.business);
	const { t } = useTranslation();

	const { id } = useParams();
	const [message, setMessage] = useState(t("The establishments are closed for this day and time"));
	const [open, setOpen] = useState(false);
	const [showFinishButton, setShowFinishButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
	const [servicesScheduled, setServicesScheduled] = useState([]);
	const [serviceTimeOptions, setServiceTimeOptions] = useState([]);
	const [unavailability, setUnavailability] = useState([]);
	const [professionalIndexFirst, setProfessionalIndexFirst] = useState(0);

	const initialize = (data, hasUnavailabilities = false) => {
		let professionals = [];
		for (let i = 0; i < data.services.length; i++) {
			for (let j = 0; j < data.services[i].professionals.length; j++) {
				if (professionals.findIndex((e) => e.id === data.services[i].professionals[j].id) === -1) {
					professionals.push(data.services[i].professionals[j]);
				}
			}
		}

		if (hasUnavailabilities) {
			setUnavailability(professionals);
		}

		let newServiceTimeOptions = data;
		let professionalIndexFirst = -1;
		newServiceTimeOptions.services.forEach((service, serviceIndex) => {
			if (!business?.allowSelectProfessional) {
				service.professionals = service.professionals.sort((a, b) => b.schedule.length - a.schedule.length)
			}
			service.professionals.forEach((professional, professionalIndex) => {
				let unavailableSlots = professional?.unavailability ?? [];
				let newSlots = [];
				let timeLapse =
					newServiceTimeOptions.business.timeLapse > 0
						? newServiceTimeOptions.business.timeLapse
						: professional.duration;
				for (let k = 0; k < professional.availability.length; k++) {
					if (new Date(professional.availability[k].start) < new Date()) {
						continue;
					}
					let currentTime = new Date(professional.availability[k].start);
					let finalTime = new Date(professional.availability[k].end);
					while (currentTime < finalTime) {
						let endTime = new Date(currentTime.getTime() + professional.duration * 60 * 1000);
						let collisions = 0;
						let nearCollisionEnd;
						for (let j = 0; j < unavailableSlots.length; j++) {
							let start = new Date(unavailableSlots[j].start);
							let end = new Date(unavailableSlots[j].end);
							if (!(end <= currentTime || start >= endTime)) {
								if (!nearCollisionEnd || end < nearCollisionEnd) {
									nearCollisionEnd = end;
								}
								collisions++;
							}
						}
						if (collisions < professional.vacancies) {
							if (endTime <= finalTime) {
								if (professionalIndexFirst == -1) {
									professionalIndexFirst = professionalIndex;
								}
								newSlots.push([
									{
										start: currentTime.toUTCString(),
										end: endTime.toUTCString(),
									},
									professional.vacancies,
								]);
							}
							currentTime = new Date(currentTime.getTime() + timeLapse * 60 * 1000);
						} else {
							currentTime = nearCollisionEnd;
						}
					}
					newServiceTimeOptions.services[serviceIndex].professionals[professionalIndex].schedule = newSlots;
				}
			});
		});
		setServiceTimeOptions(newServiceTimeOptions);
		let service = newServiceTimeOptions?.services.find((e) => e?.id === selectedServices[0]?.id);
		setProfessionalIndexFirst(professionalIndexFirst);
		selectTime(
			{
				time: service.professionals[professionalIndexFirst].schedule[0][0],
				professional: service.professionals[professionalIndexFirst],
				service: service,
			},
			0,
			newServiceTimeOptions,
			professionals
		);
	};

	useEffect(() => {
		let dates = {
			start_time: dayjs(date).startOf("day").utc().format(),
			end_time: dayjs(date).endOf("day").utc().format(),
		};
		let featureItems;
		if (selectedServices) {
			featureItems = selectedServices.map((service) => {
				return service.featureItems;
			});
		}

		setLoading(true);
		if (coupon) {
			couponServices
				.postSearchCoupon({
					...dates,
					business_id: business.id,
					services: featureItems,
					coupon_id: coupon?.coupon.coupon_id,
				})
				.then(({ data }) => {
					if (data.length === 0) {
						setOpen(true);
					}
					var newData = camelizeKeys(data[0]);
					initialize(newData);
					setLoading(false);
				})
				.catch((e) => {
					dialog
						.withTitle(t("The services are unavailable at the moment."))
						.withAction(() => props.history.goBack())
						.show();
					setLoading(false);
				});
		} else {
			searchService
				.postSearch({ ...dates, business_id: business.id, services: featureItems })
				.then(({ data }) => {
					if (data.length === 0) {
						setOpen(true);
						return;
					}
					if (data[0].services.length < selectedServices.length) {
						dialog
							.withTitle(t("There are no professionals available for these services at the moment"))
							.withAction(() => props.history.goBack())
							.show();
						return;
					}
					initialize(data[0], true);
					setLoading(false);
				})
				.catch((e) => {
					dialog
						.withTitle(t("The services are unavailable at the moment."))
						.withAction(() => props.history.goBack())
						.show();
					setLoading(false);
				});
		}
	}, []);

	const selectTime = (schedule, currentService, options, unavailabilities) => {
		let services = new Array(...servicesScheduled);
		services[currentService || currentServiceIndex] = schedule;
		setServicesScheduled(services);

		let newServiceTimeOptions = options || serviceTimeOptions;
		let newUnavailbility = unavailabilities || unavailability;

		mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
			my_custom_prop: "foo",
		});
		mixpanel.track("web - clicou continuar horário", {
			my_custom_prop: "foo",
		});

		let allTimesDefined = true;

		for (let i = 0; i < services.length; i++) {
			if (!services[i]) {
				allTimesDefined = false;
				break;
			}
		}

		if (allTimesDefined) {
			setShowFinishButton(true);
		}

		let unavailableIndex = newUnavailbility.findIndex((e) => e.id === schedule.professional.id);
		let unavailableSlots = newUnavailbility[unavailableIndex]?.unavailability ?? [];
		unavailableSlots.push(schedule.time);
		newServiceTimeOptions.services.forEach((service, i) => {
			if (service.id !== schedule.service.id) {
				let professionalIndex = service.professionals.findIndex((e) => e.id === schedule.professional.id);
				if (professionalIndex === -1) {
					return;
				}
				let professional = service.professionals[professionalIndex];
				let newSlots = [];
				let timeLapse =
					newServiceTimeOptions.business.timeLapse > 0
						? newServiceTimeOptions.business.timeLapse
						: professional.duration;
				for (let k = 0; k < professional.availability.length; k++) {
					let currentTime = new Date(professional.availability[k].start);
					let finalTime = new Date(professional.availability[k].end);
					while (currentTime < finalTime) {
						let endTime = new Date(currentTime.getTime() + professional.duration * 60 * 1000);
						let collisions = 0;
						let nearCollisionEnd;
						for (let j = 0; j < unavailableSlots.length; j++) {
							let start = new Date(unavailableSlots[j].start);
							let end = new Date(unavailableSlots[j].end);
							if (!(end <= currentTime || start >= endTime)) {
								if (!nearCollisionEnd || end < nearCollisionEnd) {
									nearCollisionEnd = end;
								}
								collisions++;
							}
						}
						if (collisions < professional.vacancies) {
							if (endTime <= finalTime) {
								newSlots.push([
									{
										start: currentTime.toUTCString(),
										end: endTime.toUTCString(),
									},
									professional.vacancies,
								]);
							}
							currentTime = new Date(currentTime.getTime() + timeLapse * 60 * 1000);
						} else {
							currentTime = nearCollisionEnd;
						}
					}
					newServiceTimeOptions.services[i].professionals[professionalIndex].schedule = newSlots;
					newUnavailbility[unavailableIndex].unavailability = unavailableSlots;
				}
			}
		});

		setServiceTimeOptions(newServiceTimeOptions);
		setUnavailability(newUnavailbility);
	};

	const goToNextPage = (services) => {
		for (let i = 0; i < services.length; i++) {
			if (!services[i]) {
				setMessage(
					t("You forgot to select the time for the") +
					(i + 1) +
					t("service, please set a time for it before continuing")
				);
				setOpen(true);
				return;
			}
		}

		props.history.push(`/checkout/${id}`, {
			recipientId: id,
			questions: serviceTimeOptions.questions,
			services: services.map((schedule, i) => {
				return {
					//questions: [],
					date: schedule.time.start,
					service_id: schedule.service.id,
					professional_id: schedule.professional.id,
					professional: {
						duration: schedule.professional.duration,
						id: schedule.professional.id,
						name: schedule.professional.name,
						price: schedule.professional.price,
						pricingType: schedule.professional.pricingType,
						avatarUrl: schedule.professional.avatarUrl,
					},
					service: {
						serviceDuration: schedule.professional.duration,
						serviceName: schedule.service.name ?? schedule.service.serviceName,
						servicePrice: schedule.service.price,
					},
					dateStart: schedule.time.start,
				};
			}),
			bookingId: bookingId,
			reservationUpdate: reservationUpdate,
			verification: true,
			feature_items: feature_items,
			coupon: coupon,
			reschedule: reschedule,
		});
	};

	const handleOk = () => {
		setOpen(!open);
		setLoading(false);
	};

	const footer = () => {
		return (
			<div style={{ display: "flex", justifyContent: "space-between", margin: "20px" }}>
				{date && (
					<div style={{ display: "flex" }}>
						<div style={{ backgroundColor: "#450067" }} className={classes.countSelected}>
							{selectedServices.length}
						</div>
						<div className={classes.nameCountServices}>{t("Services time")}</div>
					</div>
				)}
				<ColorButton
					variant="contained"
					style={{
						backgroundColor: "#450067",
						color: "white",
					}}
					className={classes.nameButton}
					onClick={() => {
						if (currentServiceIndex === selectedServices.length - 1) {
							goToNextPage(servicesScheduled);
						} else {
							setCurrentServiceIndex(currentServiceIndex + 1);
							if (!servicesScheduled[currentServiceIndex + 1]) {
								let service = serviceTimeOptions?.services.find(
									(e) => e?.id === selectedServices[currentServiceIndex + 1]?.id
								);
								let schedule = service.professionals[professionalIndexFirst].schedule;
								if (!_.isEmpty(schedule) && !_.isEmpty(schedule[0])) {
									selectTime(
										{
											time: schedule[0][0],
											professional: service.professionals[0],
											service: service,
										},
										currentServiceIndex + 1
									);
								}
							}
						}
					}}>
					{t("Next")}
				</ColorButton>
			</div>
		);
	};
	return (
		<>
			<TitleContainer
				title={<Typography className={classes.selectTimeLabel}>{t("Select Time")}</Typography>}
				handleClick={() => props.history.goBack()}
				footer={footer()}
				loading={loading}>
				<ScheduleProgress progress={3}></ScheduleProgress>
				<SEO business={business} page="Horários" />
				<div className={classes.scrollSelectedArea}>
					{serviceTimeOptions?.services?.length > 0 ? (
						<FeaturesSelected
							features={selectedServices}
							currentIndex={currentServiceIndex}
							setCurrentIndex={(index) => {
								setCurrentServiceIndex(index);
							}}
						/>
					) : (
						!loading && <Typography className={classes.noneServices}>Você removeu todos os serviços</Typography>
					)}
				</div>
				{business?.allowSelectProfessional && serviceTimeOptions?.services?.length > 0 && date ? (
					serviceTimeOptions?.services
						.filter((e) => e.id === selectedServices[currentServiceIndex].id)[0]
						?.professionals.map((professional, index) => {
							return (
								<div style={{ padding: "10px 0" }}>
									<ProfessionalDiv professional={professional} key={`professional-${index}`} professionalView />
									<TimeOptionsDiv
										options={professional.schedule}
										onSelect={selectTime}
										professional={professional}
										professionalIndex={index}
										selected={servicesScheduled.find(
											(e) => e?.service?.id === selectedServices[currentServiceIndex]?.id
										)}
										service={serviceTimeOptions?.services.find(
											(e) => e?.id === selectedServices[currentServiceIndex]?.id
										)}
										timeZone={business.timeZone}
									/>
								</div>
							);
						})
				) : (
					<>
						{serviceTimeOptions?.services?.length > 0 && (
							<>
								<BusinessDiv service={serviceTimeOptions} selectedService={currentServiceIndex}/>
								<TimeOptionsDiv
									options={
										serviceTimeOptions?.services.filter((e) => e.id === selectedServices[currentServiceIndex].id)[0]
											?.professionals[0]?.schedule
									}
									professional={
										serviceTimeOptions?.services.filter((e) => e.id === selectedServices[currentServiceIndex].id)[0]
											?.professionals[0]
									}
									professionalIndex={0}
									onSelect={selectTime}
									selected={servicesScheduled.find((e) => e?.service?.id === selectedServices[currentServiceIndex]?.id)}
									service={selectedServices[currentServiceIndex]}
									timeZone={business.timeZone}
								/>
							</>
						)}
					</>
				)}

				<ErrorDialog onOpen={open} message={message} onOk={handleOk} title={t("Attention")} />
			</TitleContainer>
			<WhatsAppButton bottom={"80px"} />
		</>
	);
};
export default withRouter(SelectScheduleTime);

import _ from "lodash";

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
    (result, key) => ({
      ...result,
      [_.camelCase(key)]: camelizeKeys(obj[key]),
    }),
    {},
    );
  }
  return obj;
};

export default camelizeKeys;
